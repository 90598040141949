<template>
  <div class="toggle-button">
    <button
      v-for="(option, index) in options"
      :key="index"
      :class="{ selected: selectedOption === option.value }"
      @click="selectOption(option.value)"
    >
      <span
        :class="`project42-button-icon__icon--right icon-${option.label}`"
        :style="`font-size: 1.5rem;`"
      ></span>
    </button>
  </div>
</template>

<script>
export default {
  name: "ToggleButton",
  props: {
    options: {
      type: Array,
      required: true,
    },
    defaultValue: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      selectedOption: this.defaultValue,
    };
  },
  methods: {
    selectOption(value) {
      this.selectedOption = value;
      this.$emit("update:selected", value);
    },
  },
};
</script>

<style scoped>
.toggle-button {
  border-radius: 8px;
  width: fit-content;
  overflow: hidden;
  border: 1px solid #ccc;
}

button {
  flex: 1;
  padding: 10px;
  background-color: white;
  cursor: pointer;
  border: none;
  transition: background-color 0.3s ease, color 0.3s ease;
}

button.selected {
  background-color: #f17023;
  color: white;
}

button:not(.selected):hover {
  background-color: #f0f0f0;
}
</style>
