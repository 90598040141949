<template>
  <div class="mycontent">
    <impersonate-user v-if="isAdmin" />
    <admin-modal v-if="isAdmin" />
    <user-info
      v-if="isAdmin ? impersonated_user : true"
      :username="
        isAdmin ? impersonated_user : $keycloak.tokenParsed?.preferred_username
      "
    />
    <toggle-button
      class="mycontent_view_selector"
      :options="toggleOptions"
      :defaultValue="viewType"
      @update:selected="handleSelection"
    />
    <div class="mycontent_tree" v-if="viewType === 'tree'">
      <tree :data="userSeriesTree" :canInteract="true" />
      <div class="mycontent_tree_actions">
        <button-icon
          :text="`Add video`"
          iconClass="icon-plus-square"
          iconSide="left"
          cssIconColor="white"
          cssBackgroundColor="#f17023"
          cssBorderRadius="12px"
          cssPadding="12px 16px"
          cssBorder="1px solid #f17023"
          cssTextColor="white"
          @click="addVideo()"
        />
        <button-icon
          :text="`Add series`"
          iconClass="icon-plus-square"
          iconSide="left"
          cssIconColor="white"
          cssBackgroundColor="#f17023"
          cssBorderRadius="12px"
          cssPadding="12px 16px"
          cssBorder="1px solid #f17023"
          cssTextColor="white"
          @click="addSerie()"
        />
      </div>
    </div>
    <div v-else>
      <div class="mycontent_videos">
        <swiper-list
          title="My Videos"
          :items="my_videos"
          type="video"
          :editable="true"
          tooltipLabel="Add New Video"
        />
      </div>
      <div class="mycontent_series">
        <swiper-list
          title="My Series"
          :items="my_series"
          type="series"
          :editable="true"
          tooltipLabel="Add New Serie"
        />
      </div>
      <div class="mycontent_seasons">
        <swiper-list
          v-if="selected_serie && selected_serie.seasonsWithVideos"
          :title="`Seasons of ${selected_serie.title}`"
          :items="selected_serie.seasonsWithVideos"
          type="season"
          :editable="true"
          tooltipLabel="Add New Season"
        />
      </div>
      <div class="mycontent_episodes">
        <swiper-list
          v-if="selected_season && selected_season.episodesWithVideos"
          :title="`Episodes of Season ${selected_season.number}`"
          :items="selected_season.episodesWithVideos"
          type="episode"
          :editable="true"
          tooltipLabel="Add New Episode"
        />
      </div>
    </div>
  </div>
</template>

<script>
import utils from "@/utils/utils.js";
import SwiperList from "@/components/home/SwiperList.vue";
import UserInfo from "@/components/shared/UserInfo.vue";
import Tree from "@/components/shared/Tree.vue";
import ToggleButton from "@/components/shared/ToggleButton.vue";
import ButtonIcon from "@/components/shared/ButtonIcon.vue";
import AdminModal from "@/components/modal/AdminModal.vue";
import ImpersonateUser from "@/components/admin/ImpersonateUser.vue";

import { mapGetters, mapActions } from "vuex";

export default {
  name: "MyContentView",
  components: {
    SwiperList,
    UserInfo,
    AdminModal,
    ImpersonateUser,
    Tree,
    ToggleButton,
    ButtonIcon,
  },
  props: {
    showViewType: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  mounted() {
    this.fetchMyVideos(this.$keycloak.tokenParsed.preferred_username);
    this.fetchMySeries(this.$keycloak.tokenParsed.preferred_username);
    this.fetchUserSeriesTree(this.$keycloak.tokenParsed.preferred_username);
  },
  data() {
    return {
      viewType: "default",
      toggleOptions: [
        { label: "carousel", value: "default" },
        { label: "tree-view", value: "tree" },
      ],
    };
  },
  computed: {
    ...mapGetters("videos", ["my_videos"]),
    ...mapGetters("series", ["my_series", "selected_serie", "userSeriesTree"]),
    ...mapGetters("seasons", ["selected_season"]),
    ...mapGetters("admin", ["impersonated_user"]),
    isAdmin() {
      return utils.isAdmin();
    },
  },
  methods: {
    ...mapActions("videos", ["fetchMyVideos"]),
    ...mapActions("series", [
      "fetchMySeries",
      "fetchUserSeriesTree",
      "setSelectedSerie",
    ]),
    ...mapActions("seasons", ["setSelectedSeason"]),
    ...mapActions("modal", ["setOpen", "setType"]),
    handleSelection(value) {
      this.setSelectedSerie(null);
      this.setSelectedSeason(null);
      this.viewType = value;
    },
    addVideo() {
      this.setOpen(true);
      this.setType("video");
    },
    addSerie() {
      this.setOpen(true);
      this.setType("series");
    },
  },
  watch: {
    impersonated_user() {
      this.fetchMyVideos(this.impersonated_user);
      this.fetchMySeries(this.impersonated_user);
    },
  },
};
</script>

<style lang="scss" scoped>
.mycontent {
  // box model
  padding: 175px 0 0 0;

  &_view_selector {
    margin-left: 4rem;
    margin-top: 2rem;
  }

  &_tree {
    margin-left: 4rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    &_actions {
      display: flex;
      gap: 1rem;
    }
  }
}

.mycontent_series {
  background-color: rgba(198, 206, 210, 1);
}

.mycontent_seasons {
  background-color: rgba(178, 176, 178, 1);
}

.mycontent_episodes {
  background-color: rgba(138, 142, 147, 1);
}
</style>
