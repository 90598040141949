var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"treeview"},[_c('ul',[_c('li',[_c('div',{staticClass:"treeview-item",on:{"click":function($event){return _vm.toggle('series')}}},[_c('span',{class:`project42-button-icon__icon--right icon-${
            _vm.open.series ? 'folder-open' : 'folder'
          }`,style:(`font-size: 1.5rem;`)}),_c('span',[_vm._v("Series")])]),(_vm.open.series)?_c('ul',_vm._l((_vm.data.series),function(serie){return _c('li',{key:serie.id},[_c('div',{staticClass:"treeview-item",on:{"click":function($event){return _vm.toggle(serie.id)}}},[_c('span',{class:`project42-button-icon__icon--right icon-${
                _vm.open[serie.id] ? 'folder-open' : 'folder'
              }`,style:(`font-size: 1.5rem;`)}),_c('span',[_vm._v(_vm._s(serie.title))]),(_vm.canInteract)?_c('b-tooltip',{attrs:{"label":"Add season to serie","position":"is-right","type":"is-dark"}},[_c('button-icon',{attrs:{"text":"","iconClass":"icon-plus-square","iconSide":"left"},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.addToSerie(serie)}}})],1):_vm._e()],1),(_vm.open[serie.id])?_c('ul',_vm._l((serie.seasons),function(season,index){return _c('li',{key:season.id},[_c('div',{staticClass:"treeview-item",on:{"click":function($event){return _vm.toggle(season.id)}}},[_c('span',{class:`project42-button-icon__icon--right icon-${
                    _vm.open[season.id] ? 'folder-open' : 'folder'
                  }`,style:(`font-size: 1.5rem;`)}),_c('span',[_vm._v(_vm._s(season.title))]),(_vm.canInteract)?_c('b-tooltip',{attrs:{"label":"Add episode to season","position":"is-right","type":"is-dark"}},[_c('button-icon',{attrs:{"text":"","iconClass":"icon-plus-square","iconSide":"left"},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.addToSeason(season, index + 1)}}})],1):_vm._e()],1),(_vm.open[season.id])?_c('ul',_vm._l((season.episodes),function(episode){return _c('li',{key:episode.id,staticClass:"treeview-item",on:{"click":function($event){$event.stopPropagation();return _vm.goToDetailsPage(episode.id)}}},[_c('span',{class:`project42-button-icon__icon--right icon-film`,style:(`font-size: 1.5rem;`)}),_c('span',[_vm._v(_vm._s(episode.title.length ? episode.title : "Untitled"))])])}),0):_vm._e()])}),0):_vm._e()])}),0):_vm._e()]),_c('li',[_c('div',{staticClass:"treeview-item",on:{"click":function($event){return _vm.toggle('unlinkedVideos')}}},[_c('span',{class:`project42-button-icon__icon--right icon-${
            _vm.open.unlinkedVideos ? 'folder-open' : 'folder'
          }`,style:(`font-size: 1.5rem;`)}),_c('span',[_vm._v("Unlinked Videos")])]),(_vm.open.unlinkedVideos)?_c('ul',_vm._l((_vm.data.unlinkedVideos),function(video){return _c('li',{key:video.id,staticClass:"treeview-item",on:{"click":function($event){$event.stopPropagation();return _vm.goToDetailsPage(video.id)}}},[_c('span',{class:`project42-button-icon__icon--right icon-film`,style:(`font-size: 1.5rem;`)}),_c('span',[_vm._v(_vm._s(video.title.length ? video.title : "Untitled"))])])}),0):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }