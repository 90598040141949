import Vue from "vue";
import axios from "axios";
import utils from "@/utils/utils";

const state = () => ({
  promotional: [],
  notPromotional: [],
  latest: [],
  most_liked: [],
  most_watched: [],
  hottest_users: [],
  hottest_actors: [],
  actorVideos: [],
  watch_later: [],
  liked: [],
  user_videos: [],
  video: {},
  isWatchLaterOpen: false,
  isLikedOpen: false,
  my_videos: [],
  selected_video: null,
  available_for_episodes: [],
  hashtags: [],
  castings: [],
  series: [],
  selected_serie: null,
  viewsDetails: [],
});

const getters = {
  promotional: (state) => JSON.parse(JSON.stringify(state.promotional)),
  notPromotional: (state) => JSON.parse(JSON.stringify(state.notPromotional)),
  latest: (state) => JSON.parse(JSON.stringify(state.latest)),
  most_liked: (state) => JSON.parse(JSON.stringify(state.most_liked)),
  most_watched: (state) => JSON.parse(JSON.stringify(state.most_watched)),
  hottest_users: (state) => JSON.parse(JSON.stringify(state.hottest_users)),
  hottest_actors: (state) => JSON.parse(JSON.stringify(state.hottest_actors)),
  actorVideos: (state) => JSON.parse(JSON.stringify(state.actorVideos)),
  watch_later: (state) => JSON.parse(JSON.stringify(state.watch_later)),
  liked: (state) => JSON.parse(JSON.stringify(state.liked)),
  user_videos: (state) => JSON.parse(JSON.stringify(state.user_videos)),
  video: (state) => JSON.parse(JSON.stringify(state.video)),
  isWatchLaterOpen: (state) => state.isWatchLaterOpen,
  isLikedOpen: (state) => state.isLikedOpen,
  my_videos: (state) => JSON.parse(JSON.stringify(state.my_videos)),
  selected_video: (state) => JSON.parse(JSON.stringify(state.selected_video)),
  available_for_episodes: (state) =>
    JSON.parse(JSON.stringify(state.available_for_episodes)),
  hashtags: (state) => JSON.parse(JSON.stringify(state.hashtags)),
  castings: (state) => JSON.parse(JSON.stringify(state.castings)),
  series: (state) => JSON.parse(JSON.stringify(state.series)),
  selected_serie: (state) => JSON.parse(JSON.stringify(state.selected_serie)),
  viewsDetails: (state) => JSON.parse(JSON.stringify(state.viewsDetails)),
};

const actions = {
  fetchPromotional({ commit }) {
    axios
      .get(`${Vue.prototype.$config.apiUrl}/video/promotional`)
      .then(async (response) => {
        let promotional = response.data;
        promotional.map(
          (video) => (video.base64 = "/assets/images/default.webp")
        );
        commit("setPromotional", promotional);
        promotional.map(async (video) => {
          video.base64 = await utils.getImage(video.image_url);
          commit("updatePromotionalBase64", video);
        });
      });
  },
  fetchNotPromotional({ commit }) {
    axios
      .get(`${Vue.prototype.$config.apiUrl}/video/not-promotional`)
      .then((response) => {
        commit("setNotPromotional", response.data.reverse());
      });
  },
  fetchLatest({ commit }) {
    axios
      .get(`${Vue.prototype.$config.apiUrl}/video/latest`)
      .then((response) => {
        commit("setLatest", response.data);
      });
  },
  fetchMostLiked({ commit }) {
    axios
      .get(`${Vue.prototype.$config.apiUrl}/video/most-liked`)
      .then((response) => {
        commit("setMostLiked", response.data);
      });
  },
  fetchMostWatched({ commit }) {
    axios
      .get(`${Vue.prototype.$config.apiUrl}/video/most-watched`)
      .then((response) => {
        commit("setMostWatched", response.data);
      });
  },
  fetchHottestUsers({ commit }) {
    axios
      .get(`${Vue.prototype.$config.apiUrl}/user/hottest`)
      .then((response) => {
        commit("setHottestUsers", response.data);
      });
  },
  fetchHottestActors({ commit }) {
    axios
      .get(`${Vue.prototype.$config.apiUrl}/user/hottest/actors`)
      .then((response) => {
        commit("setHottestActors", response.data);
      });
  },
  async fetchActorVideos({ commit }, actorName) {
    try {
      const response = await axios.get(
        `${Vue.prototype.$config.apiUrl}/video/actor/${actorName}`
      );
      commit("setActorVideos", response.data);
      return response.data;
    } catch (error) {
      console.error("Error fetching actor videos:", error);
      throw error;
    }
  },
  fetchVideo({ commit }, id) {
    return axios
      .get(`${Vue.prototype.$config.apiUrl}/video/${id}`)
      .then((response) => {
        commit("setVideo", response.data);
        return response.data;
      });
  },
  resetVideo({ commit }) {
    commit("setVideo", {});
  },
  fetchWatchLater({ commit }) {
    axios
      .get(`${Vue.prototype.$config.apiUrl}/video/watch-later`)
      .then((response) => {
        commit("setWatchLater", response.data);
      });
  },
  fetchLiked({ commit }) {
    axios.get(`${Vue.prototype.$config.apiUrl}/video/like`).then((response) => {
      commit("setLiked", response.data);
    });
  },
  fetchUserVideos({ commit }, username) {
    axios
      .get(`${Vue.prototype.$config.apiUrl}/video/user/${username}`)
      .then((response) => {
        commit("setUserVideos", response.data);
      });
  },
  saveWatchLater({ commit }, id) {
    axios
      .put(`${Vue.prototype.$config.apiUrl}/video/watch-later/${id}`)
      .then((response) => {
        commit("setWatchLater", response.data);
      });
  },
  removeWatchLater({ commit }, id) {
    axios
      .delete(`${Vue.prototype.$config.apiUrl}/video/watch-later/${id}`)
      .then((response) => {
        commit("setWatchLater", response.data);
      });
  },
  setIsWatchLaterOpen({ commit }, isOpen) {
    commit("setIsWatchLaterOpen", isOpen);
  },
  setIsLikedOpen({ commit }, isOpen) {
    commit("setIsLikedOpen", isOpen);
  },
  async fetchMyVideos({ commit }, username) {
    await axios
      .get(`${Vue.prototype.$config.apiUrl}/video/user/${username}`)
      .then((response) => {
        commit("setMyVideos", response.data);
      });
  },
  setSelectedVideo({ commit }, video) {
    commit("setSelectedVideo", video);
  },
  async createVideo({ getters, dispatch, rootGetters }, video) {
    let videoId = null;
    await axios
      .post(`${Vue.prototype.$config.apiUrl}/video`, video)
      .then(async (response) => {
        videoId = response.data;
        await dispatch(
          "fetchMyVideos",
          utils.isAdmin()
            ? rootGetters["admin/impersonated_user"]
            : Vue.prototype.$keycloak.tokenParsed.preferred_username
        );
      });
    return getters.my_videos.find((video) => video.id === videoId);
  },
  updateVideo({ dispatch, rootGetters }, video) {
    axios.put(`${Vue.prototype.$config.apiUrl}/video`, video).then(() => {
      dispatch(
        "fetchMyVideos",
        utils.isAdmin()
          ? rootGetters["admin/impersonated_user"]
          : Vue.prototype.$keycloak.tokenParsed.preferred_username
      );
      dispatch("series/refreshSelectedSerie", null, { root: true });
    });
  },
  deleteVideo({ dispatch, rootGetters }, videoId) {
    axios
      .delete(`${Vue.prototype.$config.apiUrl}/video/${videoId}`)
      .then(() =>
        dispatch(
          "fetchMyVideos",
          utils.isAdmin()
            ? rootGetters["admin/impersonated_user"]
            : Vue.prototype.$keycloak.tokenParsed.preferred_username
        )
      );
  },
  fetchAvailableForEpisodes({ commit }, username) {
    axios
      .get(
        `${Vue.prototype.$config.apiUrl}/video/available-for-episodes/user/${username}`
      )
      .then((response) => {
        commit("setAvailableForEpisodes", response.data);
      });
  },
  fetchHashtags({ commit }) {
    axios
      .get(`${Vue.prototype.$config.apiUrl}/video/hashtags`)
      .then((response) => {
        commit("setHashtags", response.data);
      });
  },
  fetchCastings({ commit }) {
    axios
      .get(`${Vue.prototype.$config.apiUrl}/video/castings`)
      .then((response) => {
        commit("setCastings", response.data);
      });
  },
  async fetchViewsDetails({ commit }, videoId) {
    try {
      const response = await axios.get(
        `${Vue.prototype.$config.apiUrl}/video/watched/${videoId}`
      );
      const viewsDetails = response.data.map((userName) => ({
        user_name: userName,
        user: userName,
      }));
      commit("setViewsDetails", viewsDetails);
      return viewsDetails;
    } catch (error) {
      console.error("Error fetching views details:", error);
      throw error;
    }
  },
  updateVideoImage({ dispatch, commit }, videoImageData) {
    axios
      .put(`${Vue.prototype.$config.apiUrl}/video/image`, videoImageData)
      .then(() => {
        commit("forceRefreshImage", videoImageData.id);
        dispatch("series/refreshSelectedSerie", null, { root: true });
        commit("seasons/forceRefreshVideoImage", videoImageData.id, {
          root: true,
        });
      });
  },
  removeVideofromPromotional({ dispatch }, videoId) {
    axios
      .delete(`${Vue.prototype.$config.apiUrl}/video/promotional/${videoId}`)
      .then(() => dispatch("fetchPromotional"))
      .then(() => dispatch("fetchNotPromotional"));
  },
  addVideoToPromotional({ dispatch }, videoId) {
    axios
      .put(`${Vue.prototype.$config.apiUrl}/video/promotional/${videoId}`)
      .then(() => dispatch("fetchPromotional"))
      .then(() => dispatch("fetchNotPromotional"));
  },
  setVideoLike({ commit }, id) {
    return axios
      .put(`${Vue.prototype.$config.apiUrl}/video/like/${id}`)
      .then((response) => {
        commit("updateVideoLikeStatus", { id, isLiked: true });
        return response.data;
      });
  },

  deleteVideoLike({ commit }, id) {
    return axios
      .delete(`${Vue.prototype.$config.apiUrl}/video/like/${id}`)
      .then((response) => {
        commit("updateVideoLikeStatus", { id, isLiked: false });
        return response.data;
      });
  },

  setVideoWatchLater({ commit }, id) {
    return axios
      .put(`${Vue.prototype.$config.apiUrl}/video/watch-later/${id}`)
      .then((response) => {
        commit("updateVideoWatchLaterStatus", { id, isWatchLater: true });
        return response.data;
      });
  },

  deleteVideoWatchLater({ commit }, id) {
    return axios
      .delete(`${Vue.prototype.$config.apiUrl}/video/watch-later/${id}`)
      .then((response) => {
        commit("updateVideoWatchLaterStatus", { id, isWatchLater: false });
        return response.data;
      });
  },
};

const mutations = {
  setPromotional(state, promotional) {
    state.promotional = JSON.parse(JSON.stringify(promotional));
  },
  setNotPromotional(state, notPromotional) {
    state.notPromotional = JSON.parse(JSON.stringify(notPromotional));
  },
  setLatest(state, latest) {
    state.latest = JSON.parse(JSON.stringify(latest));
  },
  setMostLiked(state, most_liked) {
    state.most_liked = JSON.parse(JSON.stringify(most_liked));
  },
  setMostWatched(state, most_watched) {
    state.most_watched = JSON.parse(JSON.stringify(most_watched));
  },
  setHottestUsers(state, hottest_users) {
    state.hottest_users = JSON.parse(JSON.stringify(hottest_users));
  },
  setHottestActors(state, hottest_actors) {
    state.hottest_actors = JSON.parse(JSON.stringify(hottest_actors));
  },
  setActorVideos(state, videos) {
    state.actorVideos = JSON.parse(JSON.stringify(videos));
  },
  setVideo(state, video) {
    state.video = JSON.parse(JSON.stringify(video));
  },
  setWatchLater(state, watch_later) {
    state.watch_later = JSON.parse(JSON.stringify(watch_later));
  },
  setIsWatchLaterOpen(state, isOpen) {
    state.isWatchLaterOpen = isOpen;
  },
  setLiked(state, liked) {
    state.liked = JSON.parse(JSON.stringify(liked));
  },
  setUserVideos(state, user_videos) {
    state.user_videos = JSON.parse(JSON.stringify(user_videos));
  },
  setIsLikedOpen(state, isOpen) {
    state.isLikedOpen = isOpen;
  },
  setMyVideos(state, my_videos) {
    state.my_videos = JSON.parse(JSON.stringify(my_videos));
  },
  setSelectedVideo(state, video) {
    state.selected_video = JSON.parse(JSON.stringify(video));
  },
  setAvailableForEpisodes(state, videos) {
    state.available_for_episodes = JSON.parse(JSON.stringify(videos));
  },
  setHashtags(state, hashtags) {
    state.hashtags = JSON.parse(JSON.stringify(hashtags));
  },
  setCastings(state, castings) {
    state.castings = JSON.parse(JSON.stringify(castings));
  },
  forceRefreshImage(state, videoId) {
    state.my_videos.find(
      (my_video) => my_video.id === videoId
    ).image_url += `?refresh=${Math.random()}`;
  },
  updatePromotionalBase64(state, updatedVideo) {
    state.promotional.find((video) => video.id === updatedVideo.id).base64 =
      updatedVideo.base64;
  },
  setViewsDetails(state, details) {
    state.viewsDetails = JSON.parse(JSON.stringify(details));
  },
  updateVideoLikeStatus(state, { id, isLiked }) {
    const updateVideoInList = (list) => {
      const index = list.findIndex((v) => v.id === id);
      if (index !== -1) {
        Vue.set(list[index], "is_liked", isLiked);
      }
    };

    updateVideoInList(state.promotional);
    updateVideoInList(state.notPromotional);
    updateVideoInList(state.latest);
    updateVideoInList(state.most_liked);
    updateVideoInList(state.most_watched);
    updateVideoInList(state.watch_later);
    updateVideoInList(state.liked);
    updateVideoInList(state.user_videos);
    updateVideoInList(state.my_videos);

    if (state.video.id === id) {
      Vue.set(state.video, "is_liked", isLiked);
    }
  },

  updateVideoWatchLaterStatus(state, { id, isWatchLater }) {
    const updateVideoInList = (list) => {
      const index = list.findIndex((v) => v.id === id);
      if (index !== -1) {
        Vue.set(list, index, { ...list[index], is_watch_later: isWatchLater });
      }
    };

    updateVideoInList(state.promotional);
    updateVideoInList(state.notPromotional);
    updateVideoInList(state.latest);
    updateVideoInList(state.most_liked);
    updateVideoInList(state.most_watched);
    updateVideoInList(state.watch_later);
    updateVideoInList(state.liked);
    updateVideoInList(state.user_videos);
    updateVideoInList(state.my_videos);

    if (state.video.id === id) {
      Vue.set(state.video, "is_watch_later", isWatchLater);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
