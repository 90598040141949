<template>
  <div :class="`swiper ${isSelectedSerie ? 'bg_seasons' : ''}`">
    <div class="swiper-container">
      <img
        :class="`swiper-container-image ${serie.draft ? 'image-draft' : ''}`"
        :src="image"
        :alt="serie.title"
        @click="onClickSerie()"
      />
      <div class="swiper-container-icons" v-if="selectable">
        <b-tooltip label="Image" position="is-bottom" type="is-dark">
          <div
            class="swiper-container-icons-icon icon-image"
            @click="openCropperModal"
          ></div>
        </b-tooltip>
        <b-tooltip label="Edit" position="is-bottom" type="is-dark">
          <div
            class="swiper-container-icons-icon icon-pencil"
            @click="openSerieModal"
          ></div>
        </b-tooltip>
      </div>
    </div>
    <div class="swiper-info">
      <user-avatar :size="50" :username="serie.owner?.username" />
      <div class="swiper-info-text">
        <span
          :class="`swiper-info-text-title ${
            !clickable ? 'cursor-default' : ''
          }`"
          @click="goToDetailsPage()"
          >{{ serie.title }}</span
        >
        <span class="swiper-info-text-author"
          >{{ serie.owner?.name }} {{ serie.owner?.surname }}</span
        >
        <div class="swiper-info-categories">
          <tag-item
            v-for="(category, i) in serie.categories"
            :key="i"
            :tag="getCategoryTag(category)"
            :isDeletable="false"
            @click.native="() => searchByCategory(category)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserAvatar from "@/components/shared/UserAvatar.vue";
import TagItem from "@/components/home/search/TagItem.vue";
import utils from "@/utils/utils";

import { mapGetters, mapActions } from "vuex";

export default {
  name: "SwiperItemSerie",
  emits: ["serieItem_clicked"],
  components: {
    UserAvatar,
    TagItem,
  },
  props: {
    serie: {
      type: Object,
      required: true,
    },
    clickable: {
      type: Boolean,
      default: true,
    },
    selectable: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    serie: {
      handler: async function () {
        this.image = await utils.getImage(this.serie.image_url);
      },
      immediate: true,
      deep: true,
    },
  },
  computed: {
    ...mapGetters("series", ["selected_serie"]),
    isSelectedSerie() {
      return this.selected_serie?.id === this.serie.id;
    },
    categories() {
      return this.serie.categories.map((cat) =>
        JSON.stringify({
          type: "categories",
          tag: cat.name,
        })
      );
    },
  },
  data() {
    return {
      image: "/assets/images/default.webp",
    };
  },
  methods: {
    ...mapActions("series", ["setSelectedSerie"]),
    ...mapActions("modal", ["setOpen", "setItem", "setType"]),
    ...mapActions("search", ["setTagsForSearch", "triggerSearch", "setTags"]),
    getCategoryTag(category) {
      return JSON.stringify({
        type: "categories",
        tag: category.name,
      });
    },
    searchByCategory(category) {
      this.setTags([
        JSON.stringify({ ...category, type: "categories", tag: category.name }),
      ]);
      this.setTagsForSearch({ categories: [category] });
      this.triggerSearch();
      this.$router.push({ name: "search" }).catch(() => {});
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    goToDetailsPage() {
      if (this.clickable) {
        this.$router.push({
          name: "series",
          params: { series_id: this.serie.id },
        });
      }
    },
    selectSerie(value) {
      if (value) {
        this.setSelectedSerie(this.serie);
      } else {
        this.setSelectedSerie(null);
      }
    },
    openSerieModal() {
      this.setItem(this.serie);
      this.setType("series");
      this.setOpen(true);
    },
    openCropperModal() {
      this.setItem(this.serie);
      this.setType("SeriesCropper");
      this.setOpen(true);
    },
    onClickSerie() {
      if (this.clickable) {
        this.goToDetailsPage();
        this.$emit("serieItem_clicked");
      } else {
        this.setSelectedSerie(this.serie);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.swiper {
  // Position and Layout
  flex-direction: column;
  row-gap: 8px;

  // Display and Visibility
  display: flex;

  // Box model
  width: fit-content;
  min-width: 375.37px;
  max-width: 375.37px;

  // Typography
  color: black;

  &-container {
    // position and layout
    position: relative;

    // box model
    height: 213.47px;

    &-image {
      // clipping
      object-fit: cover;

      // Box model
      border-radius: 8px;
      height: 213.47px;
      width: 375.37px;

      // background
      cursor: pointer;
    }

    &-icons {
      // position and Layout
      position: absolute;
      bottom: 5px;
      right: 5px;
      column-gap: 1rem;
      align-items: first baseline;

      // display and visibility
      display: flex;

      // variante icons WIP Davide (to discuss)
      // top: 0;
      // right: 0px;
      // background-color: #ffffffaa;
      // margin: 5px solid black;
      // width: 100%;
      // margin: 8px 0;
      // border: 5px solid transparent;
      // overflow: hidden;
      // justify-content: end;

      &-icon {
        // box model
        padding: 0px 4px;
        border-radius: 8px;

        // background
        background-color: #f17023;
        color: white;

        // typography
        font-size: 16px;

        // background
        cursor: pointer;

        &:hover {
          transform: scale(1.1);
        }
      }
    }
  }

  &-info {
    // Position and Layout
    column-gap: 15px;
    margin-bottom: 45px;

    // Display and Visibility
    display: flex;

    // Box model
    width: -webkit-fill-available;

    &-text {
      // Position and Layout
      flex-direction: column;
      row-gap: 14px;

      // Display and Visibility
      display: flex;

      // Box model
      width: -webkit-fill-available;

      &-title {
        // Display and Visibility
        display: flex;

        // Clipping
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        white-space: pre-wrap;

        // Typography
        font-weight: 700;
        letter-spacing: 1px;
        font-size: 1.25rem;
        line-height: 1.2;

        // background
        cursor: pointer;
      }

      &-author {
        // Typography
        font-size: 0.75rem;
        font-weight: 500;
        line-height: 14px;
        letter-spacing: 0.75px;
      }
    }

    &-categories {
      // Display and Visibility
      display: flex;
      width: 300px;

      // Position and Layout
      flex-wrap: wrap;
      row-gap: 0.4rem;

      // background
      cursor: pointer;
    }
  }

  &-icons {
    // Display and Visibility
    display: flex;

    &-item {
      // Position and Layout
      column-gap: 7px;

      // Display and Visibility
      display: flex;

      // Box model
      width: 150px;

      &-icon {
        // typography
        font-size: 24px;
        color: #172126;
      }

      &-text {
        // Typography
        font-size: 1.25rem;
        line-height: 24px;
        letter-spacing: 0.75px;
      }
    }
  }
}

.cursor-default {
  // background
  cursor: default;
}

.image-draft {
  opacity: 0.4;
}

.bg_seasons {
  background: rgba(178, 176, 178, 1);
}
</style>
