<template>
  <div :class="`swiper ${isSelectedSeason ? 'bg_episodes' : ''}`">
    <div class="swiper-container-image" @click="onClickSeason()">
      <div class="card">
        <header>
          <h3>&nbsp;</h3>
          <div class="season-number">{{ season.number }}</div>
          <h3>&nbsp;</h3>
          <div class="swiper-info-categories">
            <div class="episodes-count">
              {{ this.season.episodesWithVideos.length }} ep.
            </div>
          </div>
          <div class="swiper-container-icons" v-if="selectable">
            <b-tooltip label="Edit" position="is-top" type="is-dark">
              <div
                class="swiper-container-icons-icon icon-pencil"
                @click="openSeasonModal"
              ></div>
            </b-tooltip>
          </div>
        </header>
      </div>
    </div>
    <div class="swiper-info">
      <user-avatar
        v-if="season.owner"
        :size="50"
        :username="season.owner?.username"
      />
      <div class="swiper-info-text">
        <span
          :class="`swiper-info-text-description ${
            !clickable ? 'cursor-default' : ''
          }`"
          >{{ season.description }}</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import UserAvatar from "@/components/shared/UserAvatar.vue";

import { mapGetters, mapActions } from "vuex";

export default {
  name: "SwiperItemSeason",
  components: {
    UserAvatar,
  },
  props: {
    season: {
      type: Object,
      required: true,
    },
    clickable: {
      type: Boolean,
      default: true,
    },
    selectable: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters("series", ["selected_serie"]),
    ...mapGetters("seasons", ["selected_season"]),
    isSelectedSeason() {
      return this.selected_season?.id === this.season.id;
    },
  },
  methods: {
    ...mapActions("seasons", ["setSelectedSeason"]),
    ...mapActions("modal", ["setOpen", "setItem", "setType"]),
    selectSeason(value) {
      if (value) {
        this.setSelectedSeason(this.season);
      } else {
        this.setSelectedSeason(null);
      }
    },
    openSeasonModal() {
      this.setItem(this.season);
      this.setType("season");
      this.setOpen(true);
    },
    onClickSeason() {
      this.setSelectedSeason(this.season);
    },
  },
};
</script>

<style lang="scss" scoped>
.swiper {
  // Position and Layout
  flex-direction: column;
  row-gap: 8px;

  // Display and Visibility
  display: flex;

  // Box model
  width: fit-content;
  min-width: 375.37px;
  max-width: 375.37px;

  &-container {
    // position and layout
    position: relative;

    // box model
    height: 213.47px;

    &-image {
      // clipping
      object-fit: cover;

      // Box model
      border-radius: 8px;
      height: 213.47px;
      width: 375.37px;

      // background
      cursor: pointer;
    }

    &-icons {
      // position and Layout
      position: absolute;
      bottom: 5px;
      right: 5px;
      column-gap: 1rem;
      align-items: first baseline;

      // display and visibility
      display: flex;

      // variante icons WIP Davide (to discuss)
      // top: 0;
      // right: 0px;
      // background-color: #ffffffaa;
      // margin: 5px solid black;
      // width: 100%;
      // margin: 8px 0;
      // border: 5px solid transparent;
      // overflow: hidden;
      // justify-content: end;

      &-icon {
        // box model
        padding: 0px 4px;
        border-radius: 8px;

        // background
        background-color: #f17023;
        color: white;

        // typography
        font-size: 16px;

        // background
        cursor: pointer;

        &:hover {
          transform: scale(1.1);
        }
      }
    }
  }

  &-info {
    // Position and Layout
    column-gap: 15px;
    margin-bottom: 45px;

    // Display and Visibility
    display: flex;

    // Box model
    width: -webkit-fill-available;

    &-text {
      // Position and Layout
      flex-direction: column;
      row-gap: 14px;

      // Display and Visibility
      display: flex;

      // Box model
      width: -webkit-fill-available;

      &-description {
        // Display and Visibility
        display: flex;

        // Clipping
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        white-space: pre-wrap;

        // Typography
        font-weight: 700;
        letter-spacing: 1px;
        font-size: 1.25rem;
        line-height: 1.2;

        // background
        cursor: pointer;
      }

      &-author {
        // Typography
        font-size: 0.75rem;
        font-weight: 500;
        line-height: 14px;
        letter-spacing: 0.75px;
      }
    }

    &-categories {
      // Display and Visibility
      display: flex;
      width: 300px;

      // Position and Layout
      flex-wrap: wrap;
      row-gap: 0.4rem;

      // background
      cursor: pointer;
    }
  }

  &-icons {
    // Display and Visibility
    display: flex;

    &-item {
      // Position and Layout
      column-gap: 7px;

      // Display and Visibility
      display: flex;

      // Box model
      width: 150px;

      &-icon {
        // typography
        font-size: 24px;
        color: #172126;
      }

      &-text {
        // Typography
        font-size: 1.25rem;
        line-height: 24px;
        letter-spacing: 0.75px;
      }
    }
  }
}

.swiper-item-season {
  // Position and Layout
  column-gap: 27px;

  // Display and Visibility
  display: flex;

  // Box model
  border-radius: 12px;
  width: fit-content;
  // padding bottom for tooltip full visibility
  padding-top: 20px;
  padding-bottom: 20px;

  cursor: pointer;

  // Typography
  color: black;

  &__text {
    // Position and Layout
    flex-direction: column;
    row-gap: 14px;

    // Display and Visibility
    display: flex;

    // Box model
    width: -webkit-fill-available;

    &__title {
      // Display and Visibility
      display: flex;

      // Clipping
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      white-space: pre-wrap;

      // Typography
      font-weight: 700;
      letter-spacing: 1px;
    }

    &__author {
      // Typography
      font-size: 0.75rem;
      font-weight: 500;
      line-height: 14px;
      letter-spacing: 0.75px;
    }
  }

  &__actions {
    // position and layout
    align-items: center;
    column-gap: 1rem;

    // display and visibility
    display: flex;

    &-icon {
      // box model
      padding: 0px 4px;
      border-radius: 8px;

      // background
      background-color: #f17023;
      color: white;

      // typography
      font-size: 16px;

      // background
      cursor: pointer;

      &:hover {
        transform: scale(1.1);
      }
    }
  }
}

/* Card styling */
.card {
  // width: 100%;
  // max-width: 360px;
  background-color: transparent;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  margin: auto;
}

.card header {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  // background-color: rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 4px 8px rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  overflow: hidden;
  padding-top: 15px;
  padding-bottom: 27px;
  position: relative;
  width: 100%;
}
.card header h3 {
  color: #000;
  position: relative;
  // font: bold 50px Inter, sans-serif;
  font: bold 23.5px Inter, sans-serif;
  z-index: 2;
}

/* Large number */
.card .season-number {
  color: rgba(256, 256, 256, 1);
  display: inline-block;
  width: 120px;
  font: bold 80px "inter", arial-black, sans-serif;
  letter-spacing: -0.15em;
  line-height: 1em;
  font-weight: bold;
  position: relative;
  z-index: 1;
}
.card .season-number::before {
  content: "";
  // background-color: rgba(125, 125, 125, 0.5);
  background-color: #333;
  border-radius: 60px;
  display: block;
  height: 120px;
  margin: 50% 0 0 -25%;
  left: -10px;
  position: absolute;
  top: -80px;
  width: 120px;
  z-index: -1;
}

/* Episode count label */
.card .episode-count {
  background-color: #333;
  color: #fff;
  display: inline-block;
  padding: 5px 20px;
  border-radius: 18px;
  font-size: 16px;
  font-weight: 500;
}

/* Edit icon */
.card .edit-icon {
  background-color: #ff7a00;
  width: 40px;
  height: 40px;
  border-width: 0;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  color: #fff;
  cursor: pointer;
}

/* Avatar image */
.card .avatar {
  display: block;
  width: 54px;
  height: 54px;
  border-radius: 12px;
  overflow: hidden;
  bottom: -20px;
  left: 20px;
  outline: 4px solid #f47730;
  outline-offset: -4px;
}

/* Episodes count label */
.episodes-count {
  background-color: #333;
  color: #fff;
  display: inline-block;
  padding: 5px 20px;
  border-radius: 18px;
  font-size: 16px;
  font-weight: 500;
}

.cursor-pointer {
  cursor: pointer;
}

.bg_episodes {
  background-color: rgba(138, 142, 147, 1);
}
</style>
