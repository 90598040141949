<template>
  <div class="authorpage">
    <user-info :username="this.$route.params.username" />
    <toggle-button
      class="authorpage_view_selector"
      :options="toggleOptions"
      :defaultValue="viewType"
      @update:selected="handleSelection"
      v-if="isAdmin"
    />
    <div class="authorpage_tree" v-if="viewType === 'tree'">
      <tree :data="userSeriesTree" />
    </div>
    <div v-else>
      <swiper-list
        v-if="user_videos && user_videos.length > 0"
        :title="`Videos uploaded by ${$route.params.username}`"
        :items="user_videos"
      />
      <swiper-list
        v-if="userSeries && userSeries.length > 0"
        :title="`Series created by the author`"
        :items="userSeries"
        type="series"
      />
    </div>
  </div>
</template>

<script>
import utils from "@/utils/utils.js";
import SwiperList from "@/components/home/SwiperList.vue";
import UserInfo from "@/components/shared/UserInfo.vue";
import Tree from "@/components/shared/Tree.vue";
import ToggleButton from "@/components/shared/ToggleButton.vue";

import { mapGetters, mapActions } from "vuex";

export default {
  name: "AuthorPage",
  components: {
    SwiperList,
    UserInfo,
    Tree,
    ToggleButton,
  },
  props: {
    showViewType: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      viewType: "default",
      toggleOptions: [
        { label: "carousel", value: "default" },
        { label: "tree-view", value: "tree" },
      ],
    };
  },
  mounted() {
    this.fetchUserVideos(this.$route.params.username);
    this.fetchUserSeries(this.$route.params.username);
    this.fetchUserSeriesTree(this.$route.params.username);
  },
  computed: {
    ...mapGetters("videos", ["user_videos"]),
    ...mapGetters("series", [
      "userSeries",
      "my_series",
      "selected_serie",
      "userSeriesTree",
    ]),
    isAdmin() {
      return utils.isAdmin();
    },
  },
  methods: {
    ...mapActions("videos", ["fetchUserVideos"]),
    ...mapActions("series", ["fetchUserSeriesTree", "fetchUserSeries"]),
    handleSelection(value) {
      this.viewType = value;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "bulma/sass/utilities/mixins.sass";

.authorpage {
  // position and layout
  flex-direction: column;
  row-gap: 1rem;

  // display and visibility
  display: flex;

  // box model
  height: fit-content;
  padding: 175px 0 0 0;

  &_view_selector {
    margin-left: 4rem;
    margin-top: 2rem;
  }

  &_tree {
    margin-left: 4rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}

@include mobile {
  .authorpage {
    &__user {
      // position and layout
      flex-direction: column;
      row-gap: 3rem;
    }
  }
}
</style>
