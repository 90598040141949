<template>
  <b-modal
    v-model="isModalActive"
    title="Video Views Details"
    has-modal-card
    trap-focus
    :destroy-on-hide="false"
    aria-role="dialog"
    aria-modal
  >
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Views Details</p>
      </header>
      <section class="modal-card-body">
        <div class="modal-card-body__search-container">
          <b-field>
            <b-input
              v-model="searchQuery"
              placeholder="Search by name, surname or username..."
              icon-pack="fas"
              icon="search"
              expanded
              clearable
              class="custom-search__icon"
            />
          </b-field>
        </div>
        <div class="modal-card-body__table-container">
          <b-table
            :data="filteredViewsData"
            :columns="columns"
            :show-header="false"
          >
            <template #empty>
              <div class="modal-card-body__message">
                No users found matching your search
              </div>
            </template>
            <template #cell(user)="props">
              <div class="modal-card-body__user-column">
                <user-avatar :size="32" :username="props.row.user_name" />
                <span class="username">{{ props.row.user_name }}</span>
              </div>
            </template>
          </b-table>
        </div>
      </section>
    </div>
  </b-modal>
</template>

<script>
import UserAvatar from "@/components/shared/UserAvatar.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "ViewsDetailsModal",

  components: {
    UserAvatar,
  },

  props: {
    value: {
      type: Boolean,
      required: true,
    },
    videoId: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      searchQuery: "",
      columns: [
        {
          field: "user_name",
          label: "User",
        },
      ],
    };
  },

  computed: {
    ...mapGetters("videos", ["viewsDetails"]),

    isModalActive: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },

    filteredViewsData() {
      if (!this.searchQuery) {
        return this.viewsDetails;
      }
      const searchTerm = this.searchQuery.toLowerCase().trim();
      return this.viewsDetails.filter((item) =>
        item.user_name.toLowerCase().includes(searchTerm)
      );
    },
  },

  methods: {
    ...mapActions("videos", ["fetchViewsDetails"]),
  },

  watch: {
    async isModalActive(newValue) {
      if (newValue) {
        try {
          await this.fetchViewsDetails(this.videoId);
        } catch (error) {
          this.$buefy.toast.open({
            message: "Error loading views details",
            type: "is-danger",
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-card {
  &-head {
    padding-left: 1rem;
  }

  &-body {
    max-height: 70vh;
    padding: 0;

    &__search-container {
      position: sticky;
      top: 0;
      z-index: 1;
      padding: 1.25rem;
      border-bottom: 1px solid #dbdbdb;
      background: white;
    }

    &__table-container {
      height: calc(70vh - 120px);
      padding: 0 1.25rem;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        width: 7px;
        -webkit-appearance: none;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(0, 0, 0, 0.3);
        -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
      }

      scrollbar-width: thin;
      scrollbar-color: rgba(0, 0, 0, 0.3) transparent;
      -ms-overflow-style: -ms-autohiding-scrollbar;
    }

    &__message {
      padding: 2rem;
      color: #7a7a7a;
      font-size: 1.1rem;
      text-align: center;
    }

    &__user-column {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
  }
}

.custom-search {
  &__icon {
    ::v-deep .icon.is-left {
      color: #172126;
    }
  }
}
</style>
