import { render, staticRenderFns } from "./VideoModal.vue?vue&type=template&id=65787bfe&scoped=true"
import script from "./VideoModal.vue?vue&type=script&lang=js"
export * from "./VideoModal.vue?vue&type=script&lang=js"
import style0 from "./VideoModal.vue?vue&type=style&index=0&id=65787bfe&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65787bfe",
  null
  
)

export default component.exports