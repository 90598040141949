<template>
  <div
    ref="swiperList"
    class="swipercontainer"
    :class="{
      'swipercontainer-boxed': isBoxed,
      'swipercontainer-compact': isCompact,
      'swipercontainer-search': isInSearch,
    }"
  >
    <div class="swipertop" :class="{ 'swipertop-search': isInSearch }">
      <div>
        <span class="swipertop-title">{{ title }}</span> <slot name="title" />
        <b-tooltip :label="tooltipLabel" position="is-bottom" type="is-dark">
          <div
            v-if="editable"
            class="swipertop-icon icon-plus-square"
            @click="openModal()"
          ></div>
        </b-tooltip>
      </div>
      <div v-if="description">
        <span class="swipertop-description">
          {{ description }}
        </span>
      </div>
    </div>

    <div
      class="swiperlist"
      :id="id"
      :class="{ 'swiperlist-search': isInSearch }"
    >
      <div
        v-if="!hideLeft"
        class="swiperlist-arrow swiperlist-arrow-left"
        :class="{
          'swiperlist-arrow-left-boxed': isBoxed,
          'swiperlist-arrow-left--season': type === 'season',
        }"
      >
        <span
          class="swiperlist-arrow-icon icon-keyboard_arrow_left"
          :class="{
            'swiperlist-arrow-icon--season': type === 'season',
          }"
          @click="scrollLeft"
        ></span>
      </div>
      <template v-if="type === 'video' || type === 'episode'">
        <swiper-item-video
          v-for="(item, i) in items"
          :video="item"
          :key="i"
          :clickable="isSwiperItemClickable"
          :selectable="isSwiperItemSelectable"
          :type="type"
          @videoItem_clicked="$emit('swiperItem_clicked', $event)"
        />
      </template>
      <template v-if="type === 'series'">
        <swiper-item-serie
          v-for="(item, i) in items"
          :serie="item"
          :key="i"
          :clickable="isSwiperItemClickable"
          :selectable="isSwiperItemSelectable"
          @serieItem_clicked="$emit('swiperItem_clicked', $event)"
        />
      </template>
      <template v-if="type === 'season'">
        <swiper-item-season
          v-for="(item, i) in items"
          :season="item"
          :key="i"
          :selectable="isSwiperItemSelectable"
        />
      </template>
      <div
        v-if="!hideRight"
        class="swiperlist-arrow swiperlist-arrow-right"
        :class="{
          'swiperlist-arrow-right-boxed': isBoxed,
          'swiperlist-arrow-right--season': type === 'season',
        }"
      >
        <span
          class="swiperlist-arrow-icon icon-keyboard_arrow_right"
          :class="{
            'swiperlist-arrow-icon--season': type === 'season',
          }"
          @click="scrollRight"
        ></span>
      </div>
    </div>
  </div>
</template>

<script>
import SwiperItemVideo from "@/components/shared/SwiperItemVideo.vue";
import SwiperItemSerie from "@/components/shared/SwiperItemSerie.vue";
import SwiperItemSeason from "@/components/shared/SwiperItemSeason.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "SwiperList",
  components: {
    SwiperItemVideo,
    SwiperItemSerie,
    SwiperItemSeason,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: false,
    },
    type: {
      type: String,
      default: "video",
    },
    items: {
      type: Array,
      required: true,
    },
    isBoxed: {
      type: Boolean,
      required: false,
      default: false,
    },
    isCompact: {
      type: Boolean,
      required: false,
      default: false,
    },
    bgColor: {
      type: String,
      required: false,
      default: "#ffffff",
    },
    editable: {
      type: Boolean,
      default: false,
    },
    tooltipLabel: {
      type: String,
      required: false,
    },
    isInSearch: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ["swiperItem_clicked"],
  data() {
    return {
      id: null,
      hideLeft: true,
      hideRight: false,
      el: null,
    };
  },
  computed: {
    ...mapGetters("series", ["selected_serie"]),
    ...mapGetters("seasons", ["selected_season"]),
    isSwiperItemClickable() {
      return !this.editable;
    },
    isSwiperItemSelectable() {
      return this.editable;
    },
  },
  watch: {
    items() {
      this.updateScrollArrows(0);
    },
  },
  beforeMount() {
    this.id = "swiperlist_" + this._uid;
  },
  mounted() {
    this.$refs.swiperList.style.setProperty(`--bg-color`, this.bgColor);

    this.el = document.getElementById(this.id);

    const updateScrollArrows = (ms) => {
      this.updateScrollArrows(ms);
    };

    // manage arrows visibility through Observer (when el changes size)
    const observer = new ResizeObserver(function () {
      updateScrollArrows(500);
    });
    observer.observe(this.el);

    // manage arrows visibility after scrolling with horizontal scrolling
    this.el.addEventListener("scroll", () => updateScrollArrows(500));
  },
  methods: {
    ...mapActions("modal", ["setOpen", "setType"]),

    // horizontal scroll with arrows
    scrollTo(px) {
      const el = document.getElementById(this.id);
      el.scrollLeft += px;
    },
    scrollLeft() {
      this.scrollTo(-300);
    },
    scrollRight() {
      this.scrollTo(300);
    },
    openModal() {
      this.setOpen(true);
      this.setType(this.type);
    },
    updateScrollArrows(ms) {
      setTimeout(() => {
        // check if right arrow should be hidden
        this.hideRight =
          this.el.offsetWidth + this.el.scrollLeft >= this.el.scrollWidth - 1;
        // check if left arrow should be hidden
        this.hideLeft = this.el.scrollLeft == 0;
      }, ms);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "bulma/sass/utilities/mixins.sass";

.swipercontainer {
  // position and layout
  flex-direction: column;
  row-gap: 40px;

  // display and visibility
  display: flex;

  // box model
  padding: 0px 8px;

  // background
  // background-color: var(--bg-color);

  &-boxed {
    // box model
    margin: 20px;
    border-radius: 40px;
  }

  &-compact {
    // position and layout
    row-gap: 0px;

    // box model
    padding: 5px 8px;
  }
  &-search {
    margin-left: -8px;
  }
}

.swipertop {
  // position and layout
  flex-direction: column;
  row-gap: 10px;

  // display and visibility
  display: flex;

  // box model
  padding-left: calc(4rem - 8px);

  // typography
  color: black;

  &-title {
    // typography
    font-size: 2rem;
    font-weight: 700;
    margin-right: 2rem;
  }

  &-description {
    // typography
    font-size: 1rem;
  }

  &-icon {
    cursor: pointer;
    font-size: 2rem;
    color: #5a6972;
    transition: all 0.3s;

    &:hover {
      color: #394247;
      transform: scale(1.1);
    }
  }
  &-search {
    padding-left: calc(-4rem + 8px);
  }
}

.swiperlist {
  // position and layout
  column-gap: 40px;

  // display and visibility
  display: flex;

  // clipping
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
  white-space: nowrap;

  &:nth-child(2) {
    // box model
    padding-left: calc(4rem - 8px);
  }

  &-arrow {
    // position and layout
    position: absolute;
    justify-content: center;
    z-index: 1;

    // display and visibility
    display: flex;

    // box model
    width: 50px;
    height: 305.47px;

    &-icon {
      // box model
      margin-top: 92.5px;
      border-radius: 50%;

      // background
      cursor: pointer;

      // typography
      font-size: 2rem;
      color: black;

      &:hover {
        // animation
        font-size: 2.2rem;
      }

      &--season {
        // box model
        margin-top: 8px;
      }
    }

    &-left {
      // position and layout
      left: 0;

      // background
      background: linear-gradient(
        90deg,
        var(--bg-color) 21.28%,
        rgba(255, 255, 255, 0) 100%
      );

      &-boxed {
        // box model
        margin-left: 20px;
      }

      &--season {
        // box model
        height: 64px;
      }
    }

    &-right {
      // position and layout
      right: 0;

      // background
      background: linear-gradient(
        270deg,
        var(--bg-color) 21.28%,
        rgba(255, 255, 255, 0) 100%
      );

      &-boxed {
        // box model
        margin-right: 20px;
      }

      &--season {
        // box model
        height: 64px;
      }
    }
  }
  &-search {
    &:nth-child(2) {
      // box model
      padding-left: calc(-4rem + 8px);
    }
  }
}

@include mobile {
  .swiperlist {
    // clipping
    overflow-x: auto;
  }
}
</style>
