<template>
  <div id="app">
    <nav-bar />

    <modal-component></modal-component>

    <router-view :key="$route.fullPath" />
    <floating-button text="Enjoying Project42?" />
    <footer-bar />
  </div>
</template>

<script>
import NavBar from "@/components/shared/NavBar.vue";
import FooterBar from "@/components/shared/FooterBar.vue";
import ModalComponent from "@/components/shared/ModalComponent.vue";
import FloatingButton from "@/components/shared/FloatingButton.vue";

import { mapGetters } from "vuex";

export default {
  name: "App",
  components: {
    NavBar,
    FooterBar,
    ModalComponent,
    FloatingButton,
  },
  computed: {
    ...mapGetters("search", ["isSearchBoxOpen"]),
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap");
@import "@/styles/main.scss";

/* IcoMoon */
@font-face {
  font-family: "icomoon";
  src: url("~/public/assets/icomoon/icomoon.eot?udco2g");
  src: url("~/public/assets/icomoon/icomoon.eot?udco2g#iefix")
      format("embedded-opentype"),
    url("~/public/assets/icomoon/icomoon.ttf?udco2g") format("truetype"),
    url("~/public/assets/icomoon/icomoon.woff?udco2g") format("woff"),
    url("~/public/assets/icomoon/icomoon.svg?udco2g#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  font-family: "icomoon" !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-facebook:before {
  content: "\e900";
}
.icon-youtube:before {
  content: "\e901";
}
.icon-instagram:before {
  content: "\e902";
}
.icon-linkedin:before {
  content: "\e903";
}
.icon-play:before {
  content: "\e904";
}
.icon-favourites:before {
  content: "\e905";
}
.icon-info:before {
  content: "\e906";
}
.icon-clock:before {
  content: "\e907";
}
.icon-search:before {
  content: "\e908";
}
.icon-thumbsup:before {
  content: "\e909";
}
.icon-thumbsup_fill:before {
  content: "\e90a";
}
.icon-eye:before {
  content: "\e90b";
}
.icon-eye_fill:before {
  content: "\e90c";
}
.icon-calendar:before {
  content: "\e90d";
}
.icon-add:before {
  content: "\e90e";
}
.icon-check:before {
  content: "\e90f";
}
.icon-arrow_left:before {
  content: "\e910";
}
.icon-pencil:before {
  content: "\e911";
}
.icon-delete:before {
  content: "\e912";
}
.icon-close:before {
  content: "\e913";
}
.icon-lifebuoy:before {
  content: "\e914";
}
.icon-film:before {
  content: "\e915";
}
.icon-plus-square:before {
  content: "\e916";
}
.icon-keyboard_arrow_right:before {
  content: "\e917";
}
.icon-keyboard_arrow_left:before {
  content: "\e918";
}
.icon-profile:before {
  content: "\e919";
}
.icon-logout:before {
  content: "\e91a";
}
.icon-image:before {
  content: "\e91b";
}
.icon-send:before {
  content: "\e91c";
}
.icon-x:before {
  content: "\e91d";
}
.icon-trash:before {
  content: "\e91e";
}
.icon-warning:before {
  content: "\e91f";
}
.icon-person:before {
  content: "\e920";
}
.icon-bullhorn:before {
  content: "\e921";
}
.icon-star:before {
  content: "\e922";
}
.icon-picks:before {
  content: "\e923";
}
.icon-tree-view:before {
  content: "\e925";
}
.icon-carousel:before {
  content: "\e924";
}
.icon-folder:before {
  content: "\e926";
}
.icon-folder-open:before {
  content: "\e927";
}
/* IcoMoon */

html {
  ::-webkit-scrollbar {
    display: none;
  }
}

#app {
  // typography
  font-family: "Inter", sans-serif;
  max-width: 100vw;
  min-width: 100vw;
}

// class to prevent scrolling when search box is open
body.noscroll {
  // position and layout
  position: fixed;

  // clipping
  overflow: hidden;
}
</style>
