<template>
  <div class="swiper">
    <div :class="`swiper-container bg_episodes`">
      <div v-if="video.is_live" class="swiper-container-live-badge">
        <b-tag type="is-danger" size="is-medium"> LIVE </b-tag>
      </div>
      <img
        :class="`swiper-container-image ${!clickable ? 'cursor-default' : ''}`"
        :src="image"
        :alt="video.title"
        @click="goToDetailsPage(), $emit('videoItem_clicked')"
      />
      <div class="swiper-container-bottom-icons" v-if="selectable">
        <b-tooltip label="Image" position="is-bottom" type="is-dark">
          <div
            v-if="type === 'video'"
            class="swiper-container-bottom-icons-icon icon-image"
            @click="openCropperModal"
          ></div>
        </b-tooltip>
        <b-tooltip label="Edit" position="is-bottom" type="is-dark">
          <div
            class="swiper-container-bottom-icons-icon icon-pencil"
            @click="openModal"
          ></div>
        </b-tooltip>
      </div>
      <div
        class="swiper-container-icons"
        v-if="!selectable && (video.is_watched || video.is_watch_later)"
      >
        <div v-if="video.is_watched">
          <span class="swiper-container-icons-icon icon-eye"></span>
        </div>
        <div v-if="video.is_watch_later">
          <span class="swiper-container-icons-icon icon-clock"></span>
        </div>
      </div>
      <div class="swiper-container-hover-icons" v-if="!selectable">
        <b-tooltip label="Like" position="is-bottom" type="is-dark">
          <div
            :class="[
              'swiper-container-hover-icons-icon',
              {
                'is-active icon-thumbsup_fill': localVideo.is_liked,
                'icon-thumbsup': !localVideo.is_liked,
              },
            ]"
            @click.stop="handleLikeClick"
          ></div>
        </b-tooltip>
        <b-tooltip label="Add to Watchlist" position="is-bottom" type="is-dark">
          <div
            class="swiper-container-hover-icons-icon"
            :class="{ 'is-active': localVideo.is_watch_later }"
            @click.stop="handleWatchLaterClick"
          >
            <i
              :class="localVideo.is_watch_later ? 'icon-check' : 'icon-clock'"
            ></i>
          </div>
        </b-tooltip>
      </div>
    </div>
    <div class="swiper-info">
      <span
        :class="`swiper-info-text-title ${!clickable ? 'cursor-default' : ''}`"
        @click="goToAuthorPage"
      >
        <user-avatar :size="50" :username="video.owner?.username" />
      </span>

      <div class="swiper-info-text">
        <span
          :class="`swiper-info-text-title ${
            !clickable ? 'cursor-default' : ''
          }`"
          @click="goToDetailsPage()"
        >
          {{ video.title }}
        </span>
        <span class="swiper-info-text-author"
          >{{ video.owner?.name }} {{ video.owner?.surname }}</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import UserAvatar from "@/components/shared/UserAvatar.vue";
import utils from "@/utils/utils";

import { mapGetters, mapActions } from "vuex";

export default {
  name: "SwiperItemVideo",
  props: {
    video: {
      type: Object,
      required: true,
    },
    clickable: {
      type: Boolean,
      default: true,
    },
    selectable: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "video",
    },
  },
  emits: ["videoItem_clicked"],
  components: {
    UserAvatar,
  },
  computed: {
    ...mapGetters("videos", ["selected_video"]),
    ...mapGetters("videos", ["isWatchLaterOpen", "isLikedOpen"]),
  },
  data() {
    return {
      image: "/assets/images/default.webp",
      localVideo: JSON.parse(JSON.stringify(this.video)),
    };
  },
  watch: {
    video: {
      handler(newVideo) {
        this.localVideo = JSON.parse(JSON.stringify(newVideo));
      },
      deep: true,
    },
    "localVideo.image_url_thumb": {
      handler: async function (newImageUrl) {
        this.image = await utils.getImage(newImageUrl);
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions("modal", ["setOpen", "setItem", "setType"]),
    ...mapActions("videos", [
      "setVideoLike",
      "deleteVideoLike",
      "setVideoWatchLater",
      "deleteVideoWatchLater",
    ]),

    goToDetailsPage() {
      if (this.clickable) {
        this.$router.push({
          name: "video",
          params: { video_id: this.video.id },
        });
      }
    },
    openModal() {
      if (this.type === "video") {
        this.setItem(this.video);
        this.setType("video");
        this.setOpen(true);
      } else if (this.type === "episode") {
        this.setItem(this.video);
        this.setType("episode");
        this.setOpen(true);
      }
    },
    openCropperModal() {
      this.setItem(this.video);
      this.setType("VideoCropper");
      this.setOpen(true);
    },
    goToAuthorPage() {
      if (this.clickable && this.video.owner?.username) {
        this.$router.push({
          name: "author",
          params: { username: this.video.owner.username },
        });
      }
    },
    async handleLikeClick() {
      this.localVideo.is_liked = !this.localVideo.is_liked;
      try {
        if (this.localVideo.is_liked) {
          await this.setVideoLike(this.localVideo.id);
        } else {
          await this.deleteVideoLike(this.localVideo.id);
        }
      } catch (error) {
        console.error("Error toggling like:", error);
        this.localVideo.is_liked = !this.localVideo.is_liked; // Revert on error
      }
    },

    async handleWatchLaterClick() {
      this.localVideo.is_watch_later = !this.localVideo.is_watch_later;
      try {
        if (this.localVideo.is_watch_later) {
          await this.setVideoWatchLater(this.localVideo.id);
        } else {
          await this.deleteVideoWatchLater(this.localVideo.id);
        }
      } catch (error) {
        console.error("Error toggling watch later:", error);
        this.localVideo.is_watch_later = !this.localVideo.is_watch_later; // Revert on error
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.swiper {
  // Position and Layout
  flex-direction: column;
  row-gap: 8px;

  // Display and Visibility
  display: flex;

  // Box model
  width: fit-content;
  min-width: 375.37px;
  max-width: 375.37px;

  // Typography
  color: black;

  &-container {
    // position and layout
    position: relative;

    // box model
    height: 213.47px;

    &-image {
      // clipping
      object-fit: cover;

      // Box model
      border-radius: 8px;
      height: 213.47px;
      width: 375.37px;

      // background
      cursor: pointer;
    }

    &-live-badge {
      // Position and Layout
      position: absolute;
      top: 10px;
      left: 10px;
      z-index: 2;

      .tag {
        font-weight: bold;
        padding: 0.5em 1em;
        background-color: #ff3860;
      }
    }

    &-icons {
      // position and Layout
      position: absolute;
      bottom: 5px;
      right: 5px;
      column-gap: 7px;

      // display and visibility
      display: flex;

      &-icon {
        // box model
        padding: 4px;
        border-radius: 8px;

        // background
        background-color: #f17023;
        color: white;

        // typography
        font-size: 22px;
      }
    }

    &-bottom-icons {
      // position and Layout
      position: absolute;
      bottom: 5px;
      right: 5px;
      column-gap: 1rem;
      align-items: center;

      // display and visibility
      display: flex;

      &-icon {
        // box model
        padding: 0px 4px;
        border-radius: 8px;

        // background
        background-color: #f17023;
        color: white;

        // typography
        font-size: 16px;

        // background
        cursor: pointer;

        &:hover {
          transform: scale(1.1);
        }
      }
    }

    &-hover-icons {
      // Position and Layout
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      column-gap: 1rem;

      // Display and Visibility
      display: none;
      align-items: center;

      &-icon {
        // Box model
        padding: 8px;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        // Background
        background-color: rgba(255, 255, 255, 0.8);
        color: #f17023;

        // Typography
        font-size: 24px;

        // Other
        cursor: pointer;
        transition: all 0.3s ease;

        &:hover {
          transform: scale(1.1);
          background-color: rgba(255, 255, 255, 1);
        }

        &.is-active {
          background-color: #f17023;
          color: white;
        }
      }
    }

    &:hover .swiper-container-hover-icons {
      display: flex;
    }
  }

  &-info {
    // Position and Layout
    column-gap: 15px;
    margin-bottom: 45px;

    // Display and Visibility
    display: flex;

    // Box model
    width: -webkit-fill-available;

    &-text {
      // Position and Layout
      flex-direction: column;
      row-gap: 14px;

      // Display and Visibility
      display: flex;

      // Box model
      width: -webkit-fill-available;
      overflow: hidden;

      &-title {
        // Display and Visibility
        display: block;

        // Clipping
        overflow: hidden;
        display: block;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        text-overflow: ellipsis;
        white-space: nowrap;

        // Typography
        font-weight: 700;
        letter-spacing: 1px;
        font-size: 1.25rem;
        line-height: 1.2;

        // background
        cursor: pointer;
      }

      &-author {
        // Typography
        font-size: 0.75rem;
        font-weight: 500;
        line-height: 14px;
        letter-spacing: 0.75px;
      }
    }
  }

  &-numbers {
    // Display and Visibility
    display: flex;

    &-item {
      // Position and Layout
      column-gap: 5px;

      // Display and Visibility
      display: flex;

      // Box model
      width: 150px;

      &-number {
        // typography
        font-size: 0.8rem;
        font-weight: 500;
        color: #172126;
      }

      &-text {
        // Typography
        font-size: 0.8rem;
        font-weight: 300;
      }
    }
  }
}

.cursor-default {
  // background
  cursor: default;
}

.icon-thumbsup,
.icon-thumbsup_fill {
  // background
  cursor: pointer;

  &:hover {
    transform: scale(1.1);
  }
}

.icon-check,
.icon-clock {
  // typography
  font-weight: 700;
  font-size: 1.5rem;
  color: inherit;
}
</style>
