<template>
  <div class="detail-video">
    <div class="detail-video__top">
      <div class="detail-video__top__details">
        <div class="detail-video__top__details__left">
          <div
            class="detail-video__top__details__left__serie-breadcrumb"
            v-if="serieByVideo && season"
          >
            <a @click="goToSeriePage">{{ serieByVideo.title }}</a> > S{{
              season.number
            }}
            > Episode
            {{
              season.episodesWithVideos.find(
                (episode) => episode.id === video.id
              ).episode
            }}
          </div>
          <div class="detail-video__top__details__left__title">
            {{ video.title }}
            <b-tag v-if="video.is_live" type="is-danger" size="is-medium">
              LIVE
            </b-tag>
          </div>
          <div class="detail-video__top__details__left__description">
            {{ video.description }}
          </div>
          <div
            class="detail-video__top__details__left__info"
            v-if="video && video.owner"
          >
            <div class="detail-video__top__details__left__info__author">
              <user-avatar :size="40" :username="video.owner?.username" />
              <span>
                by
                <strong>
                  {{ video.owner?.name }} {{ video.owner?.surname }}
                </strong>
              </span>
            </div>
            <div class="detail-video__top__details__left__info__date">
              <div>
                <Picks :username="video.owner?.username" />
              </div>
            </div>
          </div>
          <div
            v-if="video && video.casting && video.casting.length > 0"
            class="detail-video__top__details__left__castings"
          >
            <tag-item
              v-for="(actor, i) in video.casting"
              :tag="JSON.stringify({ tag: actor, type: 'castings' })"
              :key="i"
            />
          </div>
          <div
            v-if="video && video.categories && video.categories.length > 0"
            class="detail-video__top__details__left__categories"
          >
            <tag-item
              v-for="(category, i) in video.categories"
              :tag="getCategoryTag(category)"
              :key="i"
              :isDeletable="false"
              @click.native="() => searchByCategory(category)"
            />
          </div>
          <div
            v-if="video && video.hashtags && video.hashtags.length > 0"
            class="detail-video__top__details__left__hashtags"
          >
            <tag-item
              v-for="(hashtag, i) in video.hashtags"
              :tag="JSON.stringify({ tag: hashtag, type: 'hashtags' })"
              :key="i"
            />
          </div>
          <div
            class="detail-video__top__details__left__info"
            v-if="video && video.owner"
          >
            <div class="detail-video__top__details__left__info__date">
              <span>Added on {{ video?.updated }}</span>
            </div>
          </div>
          <div class="detail-video__top__details__left__feedbacks">
            <div class="detail-video__top__details__left__feedbacks__items">
              <b-tooltip label="Like" position="is-bottom" type="is-dark">
                <div
                  :class="
                    video.is_liked
                      ? 'detail-video-icon icon-thumbsup_fill'
                      : 'detail-video-icon icon-thumbsup'
                  "
                  @click="
                    () =>
                      video.is_liked
                        ? deleteVideoLike(video.id)
                        : setVideoLike(video.id)
                  "
                ></div>
              </b-tooltip>
              <span>{{ video.likes }}</span>
            </div>
            <div class="detail-video__top__details__left__feedbacks__items">
              <span
                class="detail-video__top__details__left__feedbacks__items__views-container"
              >
                Views: {{ video.watches }}
                <span
                  v-if="isAdmin && video.watches > 0"
                  class="icon-eye"
                  @click="openViewsModal"
                ></span>
              </span>
            </div>
            <div class="detail-video__top__details__left__feedbacks__items">
              <b-tooltip label="Watchlist" position="is-bottom" type="is-dark">
                <div
                  class="detail-video__top__details__left__feedbacks__items__watchlater"
                  @click="
                    () =>
                      video.is_watch_later
                        ? deleteVideoWatchLater(video.id)
                        : setVideoWatchLater(video.id)
                  "
                >
                  <span
                    :class="video.is_watch_later ? 'icon-check' : 'icon-add'"
                  ></span>
                </div>
              </b-tooltip>
            </div>
          </div>
        </div>
        <div class="detail-video__top__details__right">
          <cover-image
            v-if="video && video.image_url"
            :imageUrl="video.image_url"
            :videoUrl="video.embed_url"
            :videoId="video.id"
          />
        </div>
      </div>
    </div>
    <swiper-list
      v-if="nextEpisodes.length > 0"
      title="Next Episodes"
      :items="nextEpisodes"
      :is-boxed="true"
      bgColor="#e8ebed"
    />
    <views-details-modal v-model="showViewsModal" :video-id="video.id" />
  </div>
</template>

<script>
import CoverImage from "@/components/detail/CoverImage.vue";
import UserAvatar from "@/components/shared/UserAvatar.vue";
import SwiperList from "@/components/home/SwiperList.vue";
import TagItem from "@/components/home/search/TagItem.vue";
import ViewsDetailsModal from "@/components/modal/ViewsDetailsModal.vue";
import Picks from "@/components/shared/Picks.vue";
import utils from "@/utils/utils.js";
import { mapGetters, mapActions } from "vuex";
import axios from "axios";

export default {
  name: "DetailVideoView",
  components: {
    CoverImage,
    UserAvatar,
    SwiperList,
    TagItem,
    ViewsDetailsModal,
    Picks,
  },
  data() {
    return {
      season: null,
      nextEpisodes: [],
      showViewsModal: false,
    };
  },
  async created() {
    this.fetchSerieByVideo(this.$route.params.video_id);
    await this.fetchVideo(this.$route.params.video_id);
    setTimeout(this.retrieveSeason, 500);
    this.fetchMostLiked();
    this.fetchMostWatched();
  },
  beforeDestroy() {
    this.resetVideo();
  },
  computed: {
    ...mapGetters("videos", ["video", "most_liked", "most_watched"]),
    ...mapGetters("series", ["serieByVideo"]),
    isAdmin() {
      return utils.isAdmin();
    },
  },
  methods: {
    ...mapActions("videos", [
      "fetchVideo",
      "fetchMostLiked",
      "fetchMostWatched",
      "resetVideo",
    ]),
    ...mapActions("series", ["fetchSerieByVideo"]),
    ...mapActions("search", ["setTagsForSearch", "triggerSearch", "setTags"]),
    getCategoryTag(category) {
      return JSON.stringify({
        type: "categories",
        tag: category.name,
      });
    },
    searchByCategory(category) {
      this.setTags([
        JSON.stringify({ ...category, type: "categories", tag: category.name }),
      ]);
      this.setTagsForSearch({ categories: [category] });
      this.triggerSearch();
      this.$router.push({ name: "search" }).catch(() => {});
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    retrieveSeason() {
      if (!this.serieByVideo) {
        this.season = null;
        this.nextEpisodes = [];
      } else {
        this.season = this.serieByVideo.seasonsWithVideos.filter((season) =>
          season.episodesWithVideos.find((video) => video.id === this.video.id)
        )[0];
        const flattenedEpisodes = this.serieByVideo.seasonsWithVideos.flatMap(
          (el) => el.episodesWithVideos
        );
        this.nextEpisodes = flattenedEpisodes.slice(
          flattenedEpisodes.findIndex((video) => video.id === this.video.id) + 1
        );
      }
    },
    goToSeriePage() {
      this.$router.push({
        name: "series",
        params: { series_id: this.serieByVideo.id },
      });
    },
    setVideoLike(videoId) {
      axios
        .put(`${this.$config.apiUrl}/video/like/${videoId}`)
        .then(() => this.fetchVideo(videoId));
    },
    deleteVideoLike(videoId) {
      axios
        .delete(`${this.$config.apiUrl}/video/like/${videoId}`)
        .then(() => this.fetchVideo(videoId));
    },
    setVideoWatchLater(videoId) {
      axios
        .put(`${this.$config.apiUrl}/video/watch-later/${videoId}`)
        .then(() => this.fetchVideo(videoId));
    },
    deleteVideoWatchLater(videoId) {
      axios
        .delete(`${this.$config.apiUrl}/video/watch-later/${videoId}`)
        .then(() => this.fetchVideo(videoId));
    },
    openViewsModal() {
      this.showViewsModal = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "bulma/sass/utilities/mixins.sass";

.icon-thumbsup {
  // background
  cursor: pointer;

  &:hover {
    transform: scale(1.1);
  }

  &_fill {
    // background
    cursor: pointer;

    &:hover {
      transform: scale(1.1);
    }
  }
}

.icon-add,
.icon-check {
  // typography
  font-weight: 700;
  font-size: 1.5rem;
  color: white;
}

.icon-eye {
  // typography
  font-size: 1.2rem;
  color: #172126;

  // box model
  margin-left: 5px;
}

.detail-video {
  &-icon {
    // typography
    font-size: 1.5rem;
    color: #172126;
  }

  &__top {
    // position and layout
    flex-direction: column;
    row-gap: 1rem;

    // display and visibility
    display: flex;

    // box model
    padding: 175px 40px 40px 40px;

    &__details {
      // position and layout
      align-items: flex-start;
      justify-content: space-between;
      column-gap: 1rem;

      // display and visibility
      display: flex;

      &__left {
        // position and layout
        flex-direction: column;
        row-gap: 1rem;

        // display and visibility
        display: flex;

        // box model
        max-width: 35%;

        // typography
        color: black;

        &__serie-breadcrumb {
          // typography
          font-weight: 700;
          font-size: 1rem;
          text-transform: uppercase;
        }

        &__title {
          // position and layout
          display: flex;
          align-items: center;
          gap: 1rem;

          // typography
          font-weight: 700;
          font-size: 3rem;
        }

        &__description {
          // typography
          font-size: 1.25rem;
          letter-spacing: 0.75px;
        }

        &__castings {
          // position and layout
          flex-wrap: wrap;
          column-gap: 0.2rem;
          row-gap: 0.2rem;

          // display and visibility
          display: flex;
        }

        &__categories {
          // position and layout
          flex-wrap: wrap;
          column-gap: 0.2rem;
          row-gap: 0.2rem;

          // display and visibility
          display: flex;

          // background
          cursor: pointer;
        }

        &__hashtags {
          // position and layout
          flex-wrap: wrap;
          column-gap: 0.2rem;
          row-gap: 0.2rem;

          // display and visibility
          display: flex;
        }

        &__info {
          // Position and Layout
          column-gap: 15px;

          // display and visibility
          display: flex;

          &__author {
            // position and layout
            column-gap: 11px;
            align-items: center;
            margin: 15px 0;

            // display and visibility
            display: flex;

            // typography
            font-size: 0.9rem;
            letter-spacing: 0.75px;
          }

          &__date {
            // position and layout
            align-items: center;
            column-gap: 8px;

            // display and visibility
            display: flex;

            // typography
            font-size: 0.8rem;
            letter-spacing: 0.75px;
          }
        }

        &__feedbacks {
          // position and layout
          align-items: center;
          column-gap: 3rem;

          // display and visibility
          display: flex;

          &__items {
            // position and layout
            align-items: center;
            column-gap: 8px;
            font-family: Courier;

            // display and visibility
            display: flex;

            &__views-container {
              align-items: center;
              display: flex;
              gap: 5px;

              .icon-eye {
                cursor: pointer;
                transition: transform 0.2s ease;

                &:hover {
                  transform: scale(1.1);
                }
              }
            }

            &__watchlater {
              // position and layout
              justify-content: center;
              align-items: center;

              // display and visibility
              display: flex;

              // box model
              width: 40px;
              height: 40px;
              border-radius: 50%;

              // background
              background-color: #f17023;

              &:hover {
                cursor: pointer;
                transform: scale(1.1);
              }
            }
          }
        }
      }
    }
  }
}

@include until-widescreen {
  .detail-video {
    &__top {
      &__details {
        // position and layout
        flex-direction: column;
        row-gap: 1rem;

        &__left {
          // position and layout
          order: 2;
          row-gap: 1rem;

          // box model
          width: 100%;
          max-width: unset;

          &__title {
            // typography
            font-size: 2rem;
          }

          &__description {
            // typography
            font-size: 1rem;
          }
        }

        &__right {
          // position and layout
          order: 1;

          // box model
          width: 100%;
        }
      }
    }
  }
}
</style>
