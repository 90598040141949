<template>
  <div class="treeview">
    <ul>
      <!-- Series -->
      <li>
        <div @click="toggle('series')" class="treeview-item">
          <span
            :class="`project42-button-icon__icon--right icon-${
              open.series ? 'folder-open' : 'folder'
            }`"
            :style="`font-size: 1.5rem;`"
          ></span>
          <span>Series</span>
        </div>
        <ul v-if="open.series">
          <li v-for="serie in data.series" :key="serie.id">
            <div @click="toggle(serie.id)" class="treeview-item">
              <span
                :class="`project42-button-icon__icon--right icon-${
                  open[serie.id] ? 'folder-open' : 'folder'
                }`"
                :style="`font-size: 1.5rem;`"
              ></span>
              <span>{{ serie.title }}</span>
              <b-tooltip
                label="Add season to serie"
                position="is-right"
                type="is-dark"
                v-if="canInteract"
              >
                <button-icon
                  text=""
                  iconClass="icon-plus-square"
                  iconSide="left"
                  @click.native.stop="addToSerie(serie)"
                />
              </b-tooltip>
            </div>
            <ul v-if="open[serie.id]">
              <li v-for="(season, index) in serie.seasons" :key="season.id">
                <div @click="toggle(season.id)" class="treeview-item">
                  <span
                    :class="`project42-button-icon__icon--right icon-${
                      open[season.id] ? 'folder-open' : 'folder'
                    }`"
                    :style="`font-size: 1.5rem;`"
                  ></span>
                  <span>{{ season.title }}</span>
                  <b-tooltip
                    label="Add episode to season"
                    position="is-right"
                    type="is-dark"
                    v-if="canInteract"
                  >
                    <button-icon
                      text=""
                      iconClass="icon-plus-square"
                      iconSide="left"
                      @click.native.stop="addToSeason(season, index + 1)"
                    />
                  </b-tooltip>
                </div>
                <ul v-if="open[season.id]">
                  <li
                    v-for="episode in season.episodes"
                    :key="episode.id"
                    class="treeview-item"
                    @click.stop="goToDetailsPage(episode.id)"
                  >
                    <span
                      :class="`project42-button-icon__icon--right icon-film`"
                      :style="`font-size: 1.5rem;`"
                    ></span>
                    <span>{{
                      episode.title.length ? episode.title : "Untitled"
                    }}</span>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </li>
      <!-- Unlinked Videos -->
      <li>
        <div @click="toggle('unlinkedVideos')" class="treeview-item">
          <span
            :class="`project42-button-icon__icon--right icon-${
              open.unlinkedVideos ? 'folder-open' : 'folder'
            }`"
            :style="`font-size: 1.5rem;`"
          ></span>
          <span>Unlinked Videos</span>
        </div>
        <ul v-if="open.unlinkedVideos">
          <li
            v-for="video in data.unlinkedVideos"
            :key="video.id"
            class="treeview-item"
            @click.stop="goToDetailsPage(video.id)"
          >
            <span
              :class="`project42-button-icon__icon--right icon-film`"
              :style="`font-size: 1.5rem;`"
            ></span>
            <span>{{ video.title.length ? video.title : "Untitled" }}</span>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
import ButtonIcon from "@/components/shared/ButtonIcon.vue";
import { mapActions } from "vuex";

export default {
  name: "TreeView",
  components: {
    ButtonIcon,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    canInteract: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      open: {},
    };
  },
  methods: {
    ...mapActions("modal", ["setOpen", "setType", "setItem"]),
    ...mapActions("series", ["setSelectedSerie"]),
    ...mapActions("seasons", ["setSelectedSeason"]),
    toggle(id) {
      this.$set(this.open, id, !this.open[id]);
    },
    async addToSerie(serie) {
      await this.setSelectedSerie(serie);
      this.setOpen(true);
      this.setType("season");
    },
    addToSeason(season, number) {
      this.setSelectedSeason({
        id: season.id,
        description: season.title,
        episodesWithVideos: season.episodes,
        number,
      });
      this.setOpen(true);
      this.setType("episode");
    },
    goToDetailsPage(videoId) {
      if (!this.canInteract) {
        this.$router.push({
          name: "video",
          params: { video_id: videoId },
        });
      }
    },
  },
};
</script>

<style>
.treeview ul {
  list-style: none;
  margin: 0;
  padding: 0 0 0 20px;
}

.treeview li {
  margin: 5px 0;
  cursor: pointer;
}

.treeview span {
  display: inline-block;
}

.treeview-item {
  display: flex;
  align-items: center;
  gap: 6px;
}
</style>
